import { useContext, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button, Icon, Loader, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { AuthContext } from 'context/AuthContext'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ExtendedSession } from 'pages/api/auth/[...nextauth]'
import {
  FormLogin as FormLoginFields,
  FormLogin as FormLoginInputs
} from 'templates/Login'

import InputController from 'components/InputController'
import Logo from 'components/Logo'
import GoogleAuth from 'components/RegisterForm/components/GoogleAuth'

import { schemaLogin } from '../schema'
import * as S from './styles'

type FormLoginProps = {
  defaultFormValues?: Partial<FormLoginFields>
  session: ExtendedSession | null
}

export const FormLogin = ({ defaultFormValues, session }: FormLoginProps) => {
  const isMobile = useMediaQuery('(max-width:1050px)')
  const isShort = useMediaQuery('(max-height:700px)')
  const isMd = useMediaQuery('(min-width:1200px) and (max-width:1290px)')
  const isSm = useMediaQuery('(min-width:1000px) and (max-width:1180px)')

  const currentImage = {
    url: !isShort ? '/images/image-login.png' : '/images/image-login-small.png',
    scale: '1.0',
    objectFit: 'cover',
    outerMargin: '32px'
  }

  const { handleSubmit, control } = useForm<FormLoginFields>({
    defaultValues: { ...defaultFormValues },
    resolver: yupResolver(schemaLogin)
  })

  const { signIn } = useContext(AuthContext)
  const [errorApi, setErrorApi] = useState('')
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [isVerified, setIsVerified] = useState(false)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [passwordInputType, setPasswordInputType] = useState('password')

  const handleInputType = () => {
    setPasswordInputType((s) => (s === 'password' ? 'text' : 'password'))
  }

  async function handleCaptchaSubmission(token: string | null) {
    try {
      if (token) {
        const response = await fetch('/api/captcha', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        })

        const result = await response.json()

        if (response.ok && result.message) {
          setIsVerified(true)
        } else {
          setIsVerified(false)
        }
      }
    } catch (e) {
      setIsVerified(false)
    }
  }

  const handleChange = (token: string | null) => {
    handleCaptchaSubmission(token)
  }

  function handleExpired() {
    setIsVerified(false)
  }
  const handleSignIn: SubmitHandler<FormLoginInputs> = async (data) => {
    if (showCaptcha && !isVerified) {
      toast.info('Confirme que você não é um robô.')
      return
    }
    const res = (await signIn(data)) as any
    if (res?.status == 429) {
      toast.info(res.message)
      recaptchaRef.current?.reset()
      setShowCaptcha(true)
    }
    if (res?.status === 401) {
      setErrorApi(res.message)
      recaptchaRef.current?.reset()
      setIsVerified(false)
      return 401
    }
    if (res.status === 200) {
      return 200
    }
    if (res?.status === 404) {
      return 404
    }
    return
  }
  const { query, push } = useRouter()

  useEffect(() => {
    const handleGoogleLogin = async () => {
      const data = {
        email: session?.user?.email || '',
        id_token: session?.idToken,
        password: ''
      }
      const res = await handleSignIn(data)
      if (res === 200) {
        return push('/visao-geral')
      }
      if (query.loading && res === 404) {
        return push('/login?error=true')
      }
      return push('/registrar')
    }

    if (session?.user && query.loading) {
      handleGoogleLogin()
    }
  }, [query.loading, session])

  if (query.loading) return <Loader />
  return (
    <Box display="flex" height="100%" width="100%">
      <S.Wrapper>
        <S.ContainerForm>
          <S.LogoWrapper>
            <Logo size="normal" id="logo" />
          </S.LogoWrapper>
          <S.Form onSubmit={handleSubmit(handleSignIn)}>
            <Stack gap={2} mb={6}>
              <S.Heading>Seja bem-vindo!</S.Heading>
              <S.SubHeading>Acesse sua base de clientes</S.SubHeading>
            </Stack>
            <InputController control={control} name="email">
              <Textfield label="E-mail:" />
            </InputController>
            <InputController control={control} name="password">
              <Textfield
                label="Senha:"
                type={passwordInputType}
                customEndAdornment={
                  <Icon
                    onClick={handleInputType}
                    icon={
                      passwordInputType === 'password'
                        ? 'visibility'
                        : 'visibilityOff'
                    }
                    sx={{ cursor: 'pointer' }}
                  />
                }
              />
            </InputController>

            <Box width="100%" mt={isMd || isSm ? 0 : -2} mb={2}>
              <Link href="/forgot-password" legacyBehavior>
                <Typography
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  variant="smBold"
                >
                  Esqueceu sua senha?
                </Typography>
              </Link>
            </Box>

            {!!errorApi && (
              <Typography color="#990017" variant="smBold">
                {errorApi}
              </Typography>
            )}
            <Box display="flex" width="100%" justifyContent="center">
              {showCaptcha && (
                <ReCAPTCHA
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY as string}
                  ref={recaptchaRef}
                  onChange={handleChange}
                  onExpired={handleExpired}
                />
              )}
            </Box>

            <Stack gap={2}>
              <Button fullWidth hasFocus buttonSize="lg" type="submit">
                ENTRAR
              </Button>

              <S.OrLine>ou</S.OrLine>
              <GoogleAuth isLogin />
            </Stack>
          </S.Form>
          <S.SignUpLink isMd={isMd} isSm={isSm}>
            <S.Paragraph>
              Não tem uma conta?{' '}
              <Link href="/registrar" passHref>
                Cadastre-se
              </Link>
            </S.Paragraph>
          </S.SignUpLink>
        </S.ContainerForm>
        <Box width="100%" boxSizing="border-box">
          <Box
            position="relative"
            padding={currentImage.outerMargin}
            boxSizing="border-box"
            bgcolor="#fff"
            display={isMobile ? 'none' : 'block'}
            height="100%"
          >
            <S.ImageWrapper>
              <Image
                alt="clientbase banner"
                src={currentImage.url}
                objectFit={currentImage.objectFit}
                objectPosition="90% 30%"
                fill
                style={{
                  scale: currentImage.scale
                }}
              />
            </S.ImageWrapper>
          </Box>
        </Box>
      </S.Wrapper>
    </Box>
  )
}
