import { Box } from '@mui/system'
import styled from '@mui/system/styled'

export const Wrapper = styled('div')(() => ({
  overflowX: 'visible',
  '@media (max-width: 1050px)': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    height: '100vh',
    '& img': {
      objectFit: 'cover'
    }
  },
  background: '#fff',
  display: 'grid',
  gridTemplateColumns: '1fr 680px',
  placeContent: 'center',
  gridTemplateRows: '100vh',
  height: '100%',
  maxHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
  '@media (min-width: 1800px)': {
    width: '80%',
    margin: '0 auto'
  },
  '@media (min-width: 2000px)': {
    width: '70%',
    margin: '0 auto'
  },
  '@media (min-width: 2150px)': {
    width: '60%',
    margin: '0 auto'
  }
}))

export const ImageWrapper = styled(Box)(() => ({
  position: 'relative',
  borderRadius: '15px',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  filter: 'drop-shadow(0px 0px 10px rgba(77, 152, 240, 0.35))',
  '-webkit-filter': 'drop-shadow(0px 0px 10px rgba(77, 152, 240, 0.35))',
  boxShadow: '0px 0px 30px rgba(77, 152, 240, 0.35)',
  zIndex: 999
}))

export const ContainerForm = styled('div')(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  maxWidth: 592,
  width: '100%',
  padding: '0 50px',
  gap: 16,
  margin: 'auto',
  '@media (max-width: 1050px)': {
    padding: '0 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '50px auto'
  }
}))

export const Heading = styled('span')(({ theme }) => ({
  fontSize: 36,
  color: theme.palette.neutral[100],
  fontWeight: 600,
  cursor: 'default',
  '@media (max-width: 1050px)': {
    fontSize: 24,
    textAlign: 'center'
  }
}))

export const SubHeading = styled('span')(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.neutral[400],
  fontWeight: 500,
  cursor: 'default',
  '@media (max-width: 1050px)': {
    fontSize: 15,
    textAlign: 'center'
  }
}))

export const Form = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  [theme.breakpoints.between(1200, 1290)]: {
    gap: 0
  },
  [theme.breakpoints.between(1000, 1180)]: {
    gap: 0
  },
  '& > div': {
    width: '100%'
  }
}))

export const OrLine = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: 16,
  cursor: 'default',
  color: '#777877',
  '&::after, &::before': {
    content: '""',
    width: '100%',
    height: '1px',
    background: '#777877'
  }
}))

export const GoogleButton = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,

  border: '1px solid #BEC0BE',
  borderRadius: 4,

  cursor: 'pointer',
  padding: '12px 0',
  boxSizing: 'border-box',
  color: '#777877'
}))

export const Paragraph = styled('p')(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.neutral[300],
  fontWeight: 500,
  width: 'fit-content',
  margin: '12px auto 0',
  cursor: 'default',
  '& a': {
    color: theme.palette.neutral[200],
    textDecoration: 'underline'
  }
}))
type SignUpLink = {
  isMd: boolean
  isSm: boolean
}
export const SignUpLink = styled(Box)(({ isMd, isSm }: SignUpLink) => ({
  alignSelf: 'start',
  justifySelf: 'end',
  position: 'absolute',
  bottom: isMd ? 50 : isSm ? 10 : 15,
  '@media (max-width: 1050px)': {
    position: 'static',
    alignSelf: 'center'
  }
}))

export const LogoWrapper = styled(Box)(() => ({
  display: 'none',
  '@media (max-width: 1050px)': {
    display: 'block'
  }
}))
